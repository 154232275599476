<template>
  <div>
    <div class="search_choice_header_d mx-auto">
      {{ $t("search.choice1_header") }}
    </div>
    <form @submit.prevent="search">
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-row position-relative" style="flex-shrink: 0">
          <div class="number_code_new_img">
            <img src="@/assets/icons/new_class.png" width="48" height="48" />
          </div>
          <div class="dropdown">
            <button
              class="btn search_number_code_dropdown_d dropdown-toggle shadow-none"
              type="button"
              id="dropdownMenuNumberCode"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ selectedNumberCode }}
            </button>
            <div
              class="dropdown-menu scrollable-menu number_code_dropdown_menu"
              aria-labelledby="dropdownMenuNumberCode"
            >
              <a
                class="dropdown-item"
                :class="{ is_new_class: code.is_new }"
                v-for="code in numberCodes"
                :key="code.value"
                @click="setNumberCode(code.value)"
                >{{
                  code.value == "-1" ? $t("search.all_codes_alt") : code.label
                }}</a
              >
            </div>
          </div>

          <div class="number_pieces_d" ref="number_pieces">
            <input
              id="number_pieces_d_1"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p1"
              @keyup="focusNext"
            />
            <input
              id="number_pieces_d_2"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p2"
              @keyup="focusNext"
            />
            <hr v-if="mask.value == '2'" />
            <input
              id="number_pieces_d_3"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p3"
              @keyup="focusNext"
            />
            <hr v-if="mask.value == '1'" />
            <input
              id="number_pieces_d_4"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p4"
              @keyup="focusNext"
            />
            <hr v-if="mask.value == '2'" />
            <input
              id="number_pieces_d_5"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p5"
              @keyup="focusNext"
            />
            <input
              id="number_pieces_d_6"
              class="form-control shadow-none"
              type="text"
              placeholder="X"
              maxlength="1"
              v-model="numberPieces.p6"
              @keyup="focusNext"
            />
          </div>

          <div>
            <button
              type="submit"
              class="btn btn-accept-filter btn-number-choice"
              ref="accept_filter"
            >
              {{ $t("search.btn") }}
            </button>
          </div>

          <div class="mask_number_d">
            <MaskNumber @change-mask="changeNumberMask" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NumberCodes from "@/utils/numberCodes";
import MaskNumber from "@/components/MaskNumber.vue";
import { mapGetters } from "vuex";

export default {
  name: "DesktopNumberChoice",
  data: () => ({
    numberCodes: NumberCodes,
    code: "-1",
    numberPieces: { p1: "", p2: "", p3: "", p4: "", p5: "", p6: "" },
    mask: {},
  }),
  computed: {
    ...mapGetters(["filterParams"]),
    selectedNumberCode: function () {
      if (this.code == "-1") return this.$t("search.all_codes_alt");
      else return "0" + this.code;
    },
    buildNumber: function () {
      return {
        code: this.code,
        mask: this.mask,
        numberPieces: this.numberPieces,
      };
    },
  },
  watch: {
    buildNumber: function (value) {
      this.$emit("change-search-number", value);
    },
  },
  methods: {
    setNumberCode: function (code) {
      this.code = code;
    },
    changeNumberMask(value) {
      this.mask = value;
    },
    search() {
      this.$emit("accept-filter");
    },
    focusNext(event) {
      let targetId = Number(event.target.id.slice(-1));
      if (event.keyCode !== 13) {
        this.numberPieces[`p${targetId}`] = event.key.replace(/[^0-9]/g, "");
      }

      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105)
      ) {
        if (targetId <= 5) {
          const input = document.getElementById(`number_pieces_d_${targetId + 1}`);
          input.focus();
          input.setSelectionRange(0, 1);
          input.classList.add("empty");
        }
        event.target.classList.remove("empty");
      } else if (event.keyCode == 8) {
        if (event.target.classList.contains("empty") && targetId !== 1) {
          const inputBs = document.getElementById(`number_pieces_d_${targetId - 1}`);
          inputBs.focus();
          inputBs.setSelectionRange(0, 1);
        } else {
          if (event.target.value.length === 0) {
            event.target.classList.add("empty");
          }
        }
      } else if (event.keyCode === 13) {
        this.$refs.accept_filter.focus();
      }

      return;
    },
  },
  created() {
    if (this.filterParams.numberParams) {
      this.code = this.filterParams.numberParams.code;
      this.numberPieces = this.filterParams.numberParams.numberPieces;
    }
    if (this.filterParams.mask) {
      this.mask = this.filterParams.mask;
    }
    this.$emit("change-search-number", this.buildNumber);
  },
  components: {
    MaskNumber,
  },
};
</script>

<style>
.number_pieces_d {
  margin-left: 6px;
}
.number_pieces_d input {
  width: 40px;
  display: inline-block;
  margin: 0 4px;
  height: 56px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1e1e1e;
  border: 0.5px solid rgba(30, 30, 30, 0.5);
  border-radius: 4px;
  text-align: center;
}
.number_pieces_d input:focus {
  border-color: #71c343;
}
.number_pieces_d input::placeholder {
  font-weight: 300 !important;
}
.btn-number-choice {
  min-width: 104px;
  margin-left: 4px;
}
.number_code_new_img {
  position: absolute;
  top: -24px;
  left: -24px;
  z-index: 1;
}
.number_pieces_d hr {
  display: inline-block;
  width: 5px;
  margin: 0;
  border-top: 1px solid rgba(30, 30, 30, 0.5);
  vertical-align: middle;
}
</style>