<template>
  <div>
    <div
        class="container-result-content container"
    >
      <div class="search_no_results_d" v-if="resultItems.length === 0">
        {{ $t("search.class_no_results") }}
      </div>
      <div class="row justify-content-md-center" v-if="resultItems.length > 0">
        <div
            class="col-md-auto"
            v-for="item in numbersToDisplay"
            :key="`item_${item.MSISDN}`"
        >
          <ClassesResultItem
              :info="item"
              :categoryId="classId"
              :mask="filterParams.mask"
              :searchType="filterParams.searchType"
              :optNumber="optNumber"
          />
        </div>

      </div>
      <div class="text-center" v-if="resultItems.length > 0">
        <button
            type="button"
            :disabled="disabledShowMoreBtn"
            class="btn btn-show-more btn-show-more-d"
            @click="showMoreNumbers()"
        >
          {{ $t("results.btn_show_more_alt") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClassesResultItem from "./ClassesResultItem";
import displayLimits from "@/utils/displayLimits";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "ClassesResultContent",
  data: () => ({
    displayLimits: displayLimits,
    resultItems: [],
    classId: null,
  }),
  computed: {
    ...mapGetters([
      "filterParams",
      "numbersByClass",
      "searchResults",
      "limitData",
      "selectedClassId",
      "showMoreButton",
      "currentPage"
    ]),
    numbers() {
      return this.numbersByClass(this.classId);
    },
    numbersToDisplay() {
      if (this.classId == null) return [];
      return this.resultItems;
      //return this.resultItems.slice(0, this.limitData[this.classId]);
    },
    optNumber() {
      const cloneParams = Object.assign({}, this.filterParams);
      if (cloneParams.searchType == "favnum") {
        return cloneParams.favParams.value ? cloneParams.favParams.value : "";
      } else {
        let numberPieces = cloneParams.numberParams.numberPieces;
        let p1 = numberPieces.p1 == "" ? "*" : numberPieces.p1;
        let p2 = numberPieces.p2 == "" ? "*" : numberPieces.p2;
        let p3 = numberPieces.p3 == "" ? "*" : numberPieces.p3;
        let p4 = numberPieces.p4 == "" ? "*" : numberPieces.p4;
        let p5 = numberPieces.p5 == "" ? "*" : numberPieces.p5;
        let p6 = numberPieces.p6 == "" ? "*" : numberPieces.p6;

        return `${p1}${p2}${p3}${p4}${p5}${p6}`;
      }
    },
    disabledShowMoreBtn() {
      return !this.showMoreButton;
      //return this.limitData[this.classId] >= this.resultItems.length;
    },
    selClassId() {
      return this.selectedClassId;
    },
  },
  watch: {
    numbers: function (value) {
      this.resultItems = cloneDeep(value);
    },
    selClassId: function (value) {
      this.classId = value;
    },
  },
  methods: {
    ...mapMutations(["SET_LIMIT_DATA", "SET_SELECTED_CLASS_ID", "SET_PAGE"]),
    ...mapActions(['fetchSearchedNumbers']),
    async showMoreNumbers() {
      //let incRows = this.limitData[this.classId] + this.displayLimits.INC_STEP;
      //this.SET_LIMIT_DATA({ classId: this.classId, count: incRows });


      this.$loading(true);
      try {
        await this.fetchSearchedNumbers({
          classId: this.classId,
          limitItems: this.displayLimits.LIMIT_ITEMS,
          inc: true
        });
        this.$loading(false);
      }
      catch (e) {
        this.$loading(false);
      }

    },
  },
  created() {
    this.classId = this.selClassId;
  },
  components: {
    ClassesResultItem,
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .container-result-content.container {
    max-width: 900px;
  }
}
</style>>
