<template>
  <div>
    <div class="row search_classes_row mx-auto">
      <div
          class="col-3"
          v-for="(cl, index) in foundClasses"
          :key="cl.id"
          :class="{ active_class: selectedClassId === cl.id }"
      >
        <div class="search_classes_radio_btn">
          <input
              :id="`radio-${index}`"
              type="radio"
              name="resultClass"
              :value="cl.id"
              v-model="resultClass"
          />
          <label :for="`radio-${index}`" class="d-flex flex-column">
            <div
                class="search_class_info_d d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                    :src="require(`@/assets/icons/${cl.img_alt}`)"
                    width="48"
                    height="48"
                />
              </div>
              <div
                  class="search_class_info_d_price"
                  v-html="costLabel(cl.cost, cl.id)"
              ></div>
            </div>
            <div class="search_class_info_d_label">{{ $t(cl.label_alt) }}</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helperMixin from "@/mixins/helper.mixin";
import displayLimits from "@/utils/displayLimits";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ClassesResult",
  mixins: [helperMixin],
  props: {
    foundClasses: Array,
  },
  data: () => ({
    displayLimits: displayLimits,
    resultClass: null,
  }),
  computed: {
    ...mapGetters(["filterParams", "selectedClassId", "limitData", "noUpdate"]),
    selClassId() {
      return this.selectedClassId;
    },
  },
  watch: {
    resultClass: async function (value) {

      this.SET_SELECTED_CLASS_ID(value);
      if (!this.limitData[value])
        this.SET_LIMIT_DATA({
          classId: value,
          count: this.displayLimits.LIMIT_ITEMS,
        });


      if(!this.noUpdate) {

        this.SET_PAGE(1);
        this.$loading(true);
        try {
          await this.fetchSearchedNumbers({
            classId: value || 2,
            limitItems: this.displayLimits.LIMIT_ITEMS,
          });
          this.$loading(false);
        }
        catch (e) {
          this.$loading(false);
        }

      }
      this.SET_NO_UPDATE(false);

    },
    selClassId: function (value) {
      this.resultClass = value;
      if (value == null) {
        if (this.foundClasses.length > 0) {
          this.resultClass = this.foundClasses[0].id;
          this.SET_SELECTED_CLASS_ID(this.resultClass);
          if (!this.limitData[this.resultClass])
            this.SET_LIMIT_DATA({
              classId: this.resultClass,
              count: this.displayLimits.LIMIT_ITEMS,
            });
        }
      }


    },
  },
  methods: {
    ...mapActions(["fetchSearchedNumbers"]),
    ...mapMutations(["SET_LIMIT_DATA", "SET_SELECTED_CLASS_ID", "SET_PAGE", "SET_NO_UPDATE"]),
    costLabel(value, classId) {
      let price = this.formatPrice(value);
      if (classId == 1) return this.$t("results.free");
      else if (classId == 4)
        return `<div class="text-nowrap">от ${price} <span class="currency">c</span></div>`;
      else
        return `<div class="text-nowrap">${price} <span class="currency">c</span></div>`;
    },
  },
  created() {
    if (this.selectedClassId) {
      this.resultClass = this.selectedClassId;
    } else {
      if (this.foundClasses.length > 0) {
        this.resultClass = this.foundClasses[0].id;
      }
    }
  },
};
</script>

<style>
.search_classes_radio_btn input[type="radio"] {
  display: none;
}
.search_classes_radio_btn label {
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  text-align: center;
  padding-bottom: 16px;
  margin: 0;
  transition: all 0.5s;
}
.search_classes_radio_btn input[type="radio"]:checked + label {
  opacity: 1;
}
.search_classes_row .col-3 {
  border: 2px solid transparent;
  transition: color 0.25s;
}
.search_classes_row .col-3.active_class {
  border-bottom: 2px solid #71c343;
  transition: all 0.5s;
}
.search_class_info_d_price {
  margin-left: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
}
.search_class_info_d_label {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
}
.search_classes_row {
  max-width: 900px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 64px;
}
</style>
