<template>
  <div class="d-flex justify-content-center">
    <div
      class="search_choice_radio_btn"
      :class="{ 'ml-2': index % 2 != 0, 'mr-2': index % 2 == 0 }"
      v-for="(ch, index) in searchChoices"
      :key="ch.value"
    >
      <input
        :id="`radio-${ch.value}`"
        type="radio"
        name="searchType"
        :value="ch.value"
        v-model="searchType"
      />
      <label :for="`radio-${ch.value}`" class="text-center">
        <div class="pb-3">
          <img
            :src="require(`@/assets/icons/${ch.img}`)"
            width="48"
            height="48"
          />
        </div>
        <div>{{ $t(ch.label) }}</div>
      </label>
    </div>
  </div>
</template>

<script>
import SearchChoices from "@/utils/searchChoices";
import { mapGetters } from "vuex";

export default {
  name: "DesktopSearchChoices",
  data: () => ({
    searchChoices: SearchChoices,
    searchType: "number",
  }),
  computed: {
    ...mapGetters(["filterParams"]),
  },
  watch: {
    searchType: function (value) {
      this.$emit("change-search-type", value);
    },
  },
  created() {
    if (this.filterParams.searchType) {
      this.searchType = this.filterParams.searchType;
    }
  },
};
</script>

<style>
.search_choice_radio_btn input[type="radio"] {
  display: none;
}
.search_choice_radio_btn label {
  width: 248px;
  background: #fbfbfb;
  border-radius: 8px;
  padding: 16px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* opacity: 0.5; */
}
.search_choice_radio_btn label div {
  opacity: 0.5;
}
.search_choice_radio_btn input[type="radio"]:checked + label {
  /* opacity: 1; */
  background: #ffffff;
  border: 1px solid #71c343;
}
.search_choice_radio_btn input[type="radio"]:checked + label div {
  opacity: 1;
}
.search_choice_radio_btn label:hover {
  cursor: pointer;
}
</style>