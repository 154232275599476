<template>
  <desktop-layout>
    <header>
      <div class="page__body-header">{{ $t("search.header") }}</div>
    </header>
    <main class="main-page__body container">
      <DesktopSearchChoices @change-search-type="changeSearchType" />
      <div class="selected_search_choice">
        <transition name="component-fade" mode="out-in">
          <component
              :is="searchTypeComponent"
              @change-search-number="changeSearchNumber"
              @accept-filter="checkCaptcha"
          ></component>
        </transition>
      </div>
      <div class="search-results-d">
        <transition name="component-fade" mode="out-in">
          <component :is="searchResultComponent"></component>
        </transition>
        <div
            class="text-center py-3"
            v-if="showResults && searchResults.length === 0 && false"
        >
          {{ $t("search.no_results") }}
        </div>
      </div>
    </main>
  </desktop-layout>
</template>

<script>
let reCaptcha = new Promise((resolve, reject) => {
  const $script = document.createElement("script");
  $script.src =
      "https://www.google.com/recaptcha/api.js?render=6Lfp-aEUAAAAAMYdMocMLADFakrKaqtC1jEjbZGV";
  resolve(document.head.appendChild($script));

  setTimeout(
      () => reject(new Error("Google reCaptcha не инициализирована")),
      3000
  );
});

import DesktopLayout from "@/layouts/DesktopLayout";
import DesktopSearchChoices from "@/components/desktop/DesktopSearchChoices.vue";
import DesktopNumberChoice from "@/components/desktop/DesktopNumberChoice.vue";
import DesktopFavChoice from "@/components/desktop/DesktopFavChoice.vue";
import DesktopSearchResults from "@/components/desktop/DesktopSearchResults.vue";
//import SearchResultsEmpty from "@/components/SearchResultsEmpty.vue";
import displayLimits from "@/utils/displayLimits";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "NumberSearch",
  data: () => ({
    displayLimits: displayLimits,
    filter: {},
    selSearchType: "number",
    selClasses: [1, 2, 3, 4, 66, 67],
    numberParams: {},
    favParams: {},
    mask: {},
  }),
  computed: {
    ...mapGetters(["filterParams", "showResults", "searchResults"]),
    searchTypeComponent() {
      let type = this.filterParams.searchType
          ? this.filterParams.searchType
          : this.selSearchType;
      if (type == "number") return DesktopNumberChoice;
      else return DesktopFavChoice;
    },
    searchResultComponent() {
      //if (this.showResults && this.searchResults.length > 0)
      return DesktopSearchResults;
      //else return SearchResultsEmpty;
    },
  },
  methods: {
    ...mapMutations([
      "SET_FILTER_PARAMS",
      "SET_SHOW_RESULTS",
      "SET_FILTER_SEARCH_TYPE",
      "SET_PAGE",
      "SET_NO_UPDATE",
      "SET_SELECTED_CLASS_ID"
    ]),
    ...mapGetters([ "selectedClassId" ]),
    ...mapActions(["fetchSearchedNumbers"]),
    changeSearchType(value) {
      this.selSearchType = value;
      this.SET_FILTER_SEARCH_TYPE(value);
    },
    changeSearchNumber(value) {
      this.mask = value.mask;

      if (this.selSearchType == "number") {
        this.numberParams = value;
      } else {
        this.favParams = value;
      }
    },
    checkCaptcha() {
      let _this = this;
      this.$loading(true);

      // _this.acceptFilter("token");
      reCaptcha.then(
          () => {
            setTimeout(() => {
              window.grecaptcha.ready(function () {
                window.grecaptcha
                    .execute("6Lfp-aEUAAAAAMYdMocMLADFakrKaqtC1jEjbZGV", {
                      action: "pick_number",
                    })
                    .then(function (token) {
                      _this.acceptFilter(token);
                    });
              });
            }, 1000);
          },
          (error) => {
            console.log(error);
          }
      );
    },
    async acceptFilter(token) {
      this.filter = {
        classes: this.selClasses,
        searchType: this.selSearchType,
        numberParams: this.numberParams,
        favParams: this.favParams,
        mask: this.mask,
      };
      this.SET_FILTER_PARAMS(this.filter);

      try {
        this.SET_PAGE(1);
        this.SET_SELECTED_CLASS_ID(this.selectedClassId() ?? 1);

        await this.fetchSearchedNumbers({
          token: token,
          ismob: 0,
          limitItems: this.displayLimits.LIMIT_ITEMS
        });

        this.$loading(false);
      } catch (error) {
        console.error(error);
        this.$loading(false);
      }

      this.SET_NO_UPDATE(false);
    },
  },
  components: {
    DesktopSearchChoices,
    DesktopLayout,
  },
};
</script>

<style>
.selected_search_choice {
  margin-top: 50px;
}
</style>
