<template>
  <div>
    <ClassesResult :found-classes="selectedClasses" />
    <ClassesResultContent />
  </div>
</template>

<script>
import ClassesResult from "./ClassesResult";
import ClassesResultContent from "./ClassesResultContent";
import NumberClasses from "@/utils/numberClasses";
import { mapGetters } from "vuex";
import { orderBy } from "lodash";

export default {
  name: "DesktopSearchResults",
  data: () => ({
    numberClasses: NumberClasses,
  }),
  computed: {
    ...mapGetters(["filterParams", "searchResults"]),
    selectedClasses() {
      const orderedClasses = orderBy(
        this.numberClasses,
        function (o) {
          return o.order;
        },
        "asc"
      );

      return orderedClasses.filter((elm1) => {
        return (this.filterParams.classes ?? []).find((elm2) => elm2 === elm1.id);
      });
    },
  },
  components: {
    ClassesResult,
    ClassesResultContent,
  },
};
</script>

<style>
.btn-show-more-d {
  width: 328px;
  margin-top: 24px;
}
</style>
