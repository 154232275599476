<template>
  <div>
    <div class="search_choice_header_d mx-auto">
      {{ $t("search.choice2_header") }}
    </div>
    <form @submit.prevent="search">
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-row position-relative">
          <div class="dropdown">
            <button
              class="btn search_number_code_dropdown_d dropdown-toggle shadow-none"
              type="button"
              id="dropdownMenuNumberCode"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ selectedNumberCode }}
            </button>
            <div
              class="dropdown-menu scrollable-menu number_code_dropdown_menu"
              aria-labelledby="dropdownMenuNumberCode"
            >
              <a
                class="dropdown-item"
                :class="{ is_new_class: code.is_new }"
                v-for="code in numberCodes"
                :key="code.value"
                @click="setNumberCode(code.value)"
                >{{
                  code.value == "-1" ? $t("search.all_codes_alt") : code.label
                }}</a
              >
            </div>
          </div>
          <div class="fav_number_wrapper_d ml-2">
            <input
              class="form-control mx-auto shadow-none"
              type="text"
              maxlength="4"
              v-model="favNumber"
              @keyup="onKeyUp"
            />
          </div>
          <div>
            <button
              type="submit"
              class="btn btn-accept-filter btn-fav-choice"
              :disabled="btnDisabled"
            >
              {{ $t("search.btn") }}
            </button>
          </div>

          <div class="mask_number_d">
            <MaskNumber @change-mask="changeNumberMask" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import MaskNumber from "@/components/MaskNumber.vue";
import NumberCodes from "@/utils/numberCodes";
import { mapGetters } from "vuex";

export default {
  name: "DesktopFavChoice",
  data: () => ({
    numberCodes: NumberCodes,
    favNumber: "",
    code: "-1",
    mask: {},
  }),
  computed: {
    ...mapGetters(["filterParams"]),
    btnDisabled() {
      return this.favNumber.length < 2;
    },
    selectedNumberCode: function () {
      if (this.code == "-1") return this.$t("search.all_codes_alt");
      else return "0" + this.code;
    },
    buildNumber: function () {
      return {
        code: this.code,
        value: this.favNumber,
        mask: this.mask,
      };
    },
  },
  watch: {
    buildNumber: function (value) {
      this.$emit("change-search-number", value);
    },
  },
  methods: {
    setNumberCode: function (code) {
      this.code = code;
    },
    changeNumberMask(value) {
      this.mask = value;
    },
    onKeyUp(event) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    search() {
      this.$emit("accept-filter");
    },
  },
  created() {
    if (
      this.filterParams.favParams &&
      Object.keys(this.filterParams.favParams).length !== 0
    ) {
      this.favNumber = this.filterParams.favParams.value;
      this.code = this.filterParams.favParams.code;
    }
    if (this.filterParams.mask) {
      this.mask = this.filterParams.mask;
    }
    this.$emit("change-search-number", this.buildNumber);
  },
  components: {
    MaskNumber,
  },
};
</script>

<style>
.fav_number_wrapper_d input {
  width: 248px;
  text-align: center;
  padding: 10px 12px;
  height: 56px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.48em;
  color: #000000;
  border: 0.5px solid rgba(30, 30, 30, 0.5);
  border-radius: 4px;
}
.fav_number_wrapper_d input:focus {
  border-color: #71c343;
}
.btn-fav-choice {
  min-width: 104px;
  margin-left: 8px;
}
</style>